import { CometChat } from "@cometchat-pro/chat";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect, User } from "firebase/auth";
import { AUTH_KEY } from "../config";
import app from "../config/firebase";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export const handleCometChatLogin = (firebaseUser: User) => {
  return new Promise((resolve, reject) => {
    if (!/(.*@cometchat.com)|(.*@inscripts.in)|(.*@inscripts.com)|(.*@atomchat.com)/.test(firebaseUser.email!)) {
      auth.signOut();
      alert("Please login with your work email.");
      return;
    }
  
    CometChat.login(firebaseUser.uid, AUTH_KEY).then(
      (user) => {
        console.log("Login Successful:", { user });
        resolve(user);
      },
      (_error) => {
        const user = new CometChat.User(firebaseUser.uid);
        user.setName(firebaseUser.displayName || firebaseUser.uid);
        if (firebaseUser.photoURL) {
          user.setAvatar(firebaseUser.photoURL);
        }
        CometChat.createUser(user, AUTH_KEY).then(
          async (_cometChatUser) => {
            console.log("user created", user);
            await CometChat.login(user.getUid(), AUTH_KEY);
            resolve(user);
          },
          (error) => {
            console.log("error", error);
            return error;
          }
        );
      }
    );
  })
}

const signInIntoApp = () => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const firebaseUser = result.user;
        const user = await handleCometChatLogin(firebaseUser);
        resolve(user);
      })
      .catch((error) => {
        const errorMessage = error.message;
        if (error.code === "auth/popup-blocked") {
          signInWithRedirect(auth, provider);
          return;
        }
        if (error.code !== "auth/popup-closed-by-user") {
          alert(`Some error occurred ${errorMessage}`);
        }
        reject(error);
      });
  });
};

export default signInIntoApp;
